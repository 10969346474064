import React, { Component } from 'react'

export class login extends Component {
    render() {
        return (
            <div>
                <h1>login page</h1>
           <p> Coming soon </p>




            </div>
        )
    }
}

export default login
