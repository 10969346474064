import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

import './App.css';

// components
import Navbar from './components/navbar';

// pages
import home from './pages/home';
import login from './pages/login';
import signup from './pages/signup';
import microbe from './pages/microbe';
import diseases from './pages/diseases';




class App extends Component {

  componentDidMount() {
    console.log('did i get called');
  }
  render() {
    return (
      <div className="App">
        <Router>
          <Navbar />
          <Switch>
            <Route exact path="/" component={home} />
            <Route exact path="/home" component={home} />
            <Route exact path="/microbe" component={microbe} />
            <Route exact path="/diseases" component={diseases} />
            <div className="container">
              <Route exact path="/login" component={login} />
              <Route exact path="/signup" component={signup} />

            </div>
          </Switch>

        </Router>
      </div>
    );
  }
}

export default App;