import React, { Component } from 'react'

export class signup extends Component {
    render() {
        return (
            <div>
                <h1>Signup page</h1>
            </div>
        )
    }
}

export default signup
