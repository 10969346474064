import React, { Component } from 'react';
import Link from 'react-router-dom/Link';
//material UI stuff
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Button from '@material-ui/core/Button';
import longLogo from './mibdX_logo_long_white.svg';

//<Button color="inherit" component={Link} to="/microbe"> Microbe</Button>
export class navbar extends Component {
    render() {
        return (
            <AppBar style={{ background: '#328C6F' }}>

                <Toolbar >
                    <img src={longLogo} alt='not found' style={{ position: 'absolute', left: '40px', height: '50px' }} />
                    <Button color="inherit"  style={{ margin: "0 0 0 80%" }} component={Link} to="/login">Login</Button>
                    <Button color="inherit" style={{ margin: "0 0 0 1%" }}  component={Link} to="/">Home</Button>
                    <Button color="inherit" style={{ margin: "0 0 0 1%" }}  component={Link} to="/signup"> Signup</Button>

                </Toolbar>
            </AppBar>
        )
    }
}
export default navbar
//title={<img src="./mibdx_orange.png"></img>}
//<img src={shortLogo} alt='not found' style={{ margin: "0 0 0 2%" , height: '50px'}} />