import React from "react";
import { useSpeechSynthesis } from "react-speech-kit";

const PronounciationSpeaker = ({ microbe_name }) => {
    const { speak } = useSpeechSynthesis();

    return (
        <div>
            <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css"></link>
            <button id="pronounciationButton" style={{ fontFamily:'Avenir Next LT Pro' ,fontSize: '14px', color: '#A11806' }} onClick={() => speak({text: microbe_name})}>Pronounciation<i
                className="fa fa-play"></i></button>
        </div>
    )

}

export default PronounciationSpeaker;