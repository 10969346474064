
import React, { Component } from 'react'
import axios from 'axios';
import Chart from "react-google-charts";

import { withRouter } from 'react-router-dom';
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';


import '../App.css';
export class home extends Component {
    constructor(props) {
        super(props);
        this.state = {
            autocompleteMicrobeNames: ['loading'],
            chartData: null,
            diseasesData: null,
            searchMicrobeName: null,
            backendURL: 'https://clickabledummy-84cc3.uc.r.appspot.com/',
            //selectedMicrobeName: null,
            chartEvents: [
                {
                    eventName: "ready",
                    callback: ({ chartWrapper, google }) => {
                        const chart = chartWrapper.getChart();
                        google.visualization.events.addListener(chart, "click", e => {
                            this.onPieChartClick(e['targetID']);
                        });
                    }
                }
            ]
        };
    }

    onPieChartClick(_sliceName) {
        if (typeof (_sliceName) === 'string') {
            if (_sliceName.includes('slice#')) {
                let sliceNumber = parseInt(_sliceName.toString().split('slice#')[1]);
                let selectedDiseaseName = this.state.diseasesData[sliceNumber + 1][0];
                if (selectedDiseaseName[0] === ' ') {
                    selectedDiseaseName = selectedDiseaseName.substring(1);
                }
                const sendDiseaseData = {
                    diseaseName: selectedDiseaseName
                };

                axios.post(this.state.backendURL+'searchDisease', sendDiseaseData)

                    .then(res => {

                        if (res.data.errors === 'none') {
                            this.props.history.push({
                                pathname: '/diseases',
                                search: '?' + selectedDiseaseName,
                                state: { diseaseData: res.data }

                            })
                        }
                        else {
                            alert(res.data.errors);
                        }
                    })
                    .catch(err => {
                        alert('could not get response from backend');
                        console.log(err.response.data);
                    });

            }
            else {
                alert('select by clicking on the slice of the piechart.');
            }
        }
        else {
            alert('select by clicking on the slice of the piechart.');
        }
    }


    handleMicrobeNameChange = (event) => {
        this.setState({
            searchMicrobeName: event.target.value
        });

    }

    handleSubmit = (event,value) => {
        event.preventDefault();
        const sendMicrobeData = {
            microbeName: value
        };

        axios.post(this.state.backendURL+'searchMicrobe', sendMicrobeData)

            .then(res => {

                if (res.data.errors === 'none') {
                    this.props.history.push({
                        pathname: '/microbe',
                        search: '?' + value.toString(),
                        state: { microbeData: res.data }

                    })
                }
                else {
                    alert(res.data.errors);
                }
            })
            .catch(err => {
                alert('could not get response from backend');
                console.log(err);
            });

    }


    componentDidMount() {

        axios.get(this.state.backendURL+'pieChartDiseases')

            .then(res => {
                this.setState({
                    diseasesData: res.data
                })
            })
            .catch(err => console.log(err));


        axios.get(this.state.backendURL+'autocompleteMicrobes')

            .then(res => {
                this.setState({
                    autocompleteMicrobeNames: res.data
                })
            })
            .catch(err => console.log(err));
    }

    render() {
        let pieChartMarkup = '<p>loading....<p>';
        if (this.state.diseasesData) {

            pieChartMarkup = <Chart className="piechart-container" width={"720px"} height={"650px"} chartType="PieChart" loader={<div>Loading Chart</div>} data={this.state.diseasesData} options={{title:"Microbiome By Diseases", }} rootProps={{ "data-testid": "1" }} chartEvents={this.state.chartEvents} />;

        }
        else {
            pieChartMarkup = 'loading....';
        }
       
        return (

            <div>
                <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/ionicons/2.0.1/css/ionicons.min.css" />

                <link rel="stylesheet" href="//netdna.bootstrapcdn.com/bootstrap/3.0.3/css/bootstrap.min.css" />
                
                <div className="searchPortal">

                    <div class="col-sm-12">
                        <h1 style={{fontFamily:'Avenir Next LT Pro', textAlign: 'marginLeft' }}>Microbiome Search Portal</h1>
                        <div style={{ width: '80%' }}>
                            <Autocomplete
                                size={"small"}


                                id="free-solo-demo"
                                options={this.state.autocompleteMicrobeNames}
                                onKeyDown={(event ) => {
                                    if (event.key === 'Enter')
                                        this.handleSubmit(event,event.target.value)
                                }}
                                renderOption={(option) => (
                                    <Typography style={{ fontFamily:'Avenir Next LT Pro' , fontSize: 18 }}>{option}</Typography>
                                  )}
                                style={{ width: '80%'}}
                                onChange={(e, value) => this.handleSubmit(e,value)}
                                renderInput={(params) => (
                                    <TextField 
                                    {...params}   
                                    placeholder="Enter the name of the microbe " 
                                    margin="normal" 
                                    variant="outlined" 
                                    inputProps={{ ...params.inputProps, style: { fontSize: 16 } }}
                                    InputLabelProps={{...params.InputLabelProps, style: {fontSize: 16}}}
                                    />
                                )}
                            />

                        </div>
                    </div>
                </div>
                <div>
                    <table>
                        <tr>
                            <td style={{ paddingLeft: '30px', width: '55%' }}>
                                <br></br><br></br>
                                <h1 style={{fontFamily:'Avenir Next LT Pro', textAlign: 'center' }}>Microbiome In Human Health</h1>
                                <p style={{textAlign: 'center', fontSize: '20px', paddingLeft: '30px',fontFamily:'Avenir Next LT Pro' }}>
                                    The human microbiome comprises of bacteria, archaea, viruses and eukaryotes which reside within and outside our bodies. These organisms impact human physiology, both in health and in disease, contributing to the enhancement or impairment of metabolic and immune functions. An alteration in the intestinal microbial community plays a major role in human health and disease pathogenesis. These alterations result from lifestyle and the presence of an underlying disease.
                                    Dysbiosis increases host susceptibility to infection, and the nature of which depends on the anatomical site involved. It is therefore important to understand the microbial composition and activities of the human microbiome as they contribute to health and disease.
                                </p>
                            </td>
                            <td style={{ width: '60%' }}>
                                <br></br><br></br><br></br>
                                <embed style={{ width: '620px', height: '320px', display: 'block', marginLeft: '20%', marginRight: 'auto' }}
                                    src="https://www.youtube.com/embed/gTa0lgs9Uig">
                                </embed>
                                

                            </td>
                        </tr>
                    </table>
                </div>
                {pieChartMarkup}

                <div className="footer-dark">
                    <footer>
                        <div className="container">
                            <div className="row">
                                <div className="col-sm-6 col-md-3 item">
                                    <h3>Services</h3>
                                    <ul>
                                        <li>Microbiome Data Analysis</li> 
                                        <li>Trancriptomics Data Analysis</li>
                                        <li>Genomics Data Analysis</li>
                                         <li>Clinical Data Analysis</li>
                                          <li>Epigenomics Data Analysis </li>
                                           <li>Biomarkers prediction</li>
                                           <li>Protenomics Data Analysis </li>
                                      <li>Deep Learning Models</li>
                                           <li>Microbiome Data Analysis</li>
                                           <li>Bioinformatics App Development</li>
                                    </ul>
                                </div>
                                <div className="col-sm-6 col-md-3 item">
                                    <h3>About</h3>
                                    <ul>
                                        <li><a href="https://deepnostix.com/">Company</a></li>
                                        <li><a href="https://deepnostix.com/#portfolio">Protfolio</a></li>
                                        <li><a href="https://deepnostix.com/#portfolio">Team</a></li>
                                        <li><a href="https://deepnostix.com/#portfolio">Careers</a></li>
                                        <li><a href="https://deepnostix.com/#contact">Contact Us </a></li>
                                    </ul>
                                </div>
                                <div className="col-md-6 item text">
                                    <h3>deepnostiX-mibdX</h3>
                                    <p>We provide diverse nature of services from classical data analysis to the development of deep learning
                                        models for biomarkers identification. We also develop apps for biological data. Which are the key features of
                                        deepnostiX</p>
                                </div>
                                <div className="col item social">
                                <i className="icon ion-social-facebook" style={{ paddingRight: '4px'}}></i>
                                 
                                <i className="icon ion-social-twitter" style={{ paddingLeft: '10px' }}></i>
                          <i className="icon ion-social-linkedin" style={{ paddingLeft: '10px' }}></i>
                          <ul>
                            <li>< a href="https://en-gb.facebook.com/">Facebook</a></li>
                            <li>< a href="https://twitter.com/account/access">Twitter</a></li>                      
                            <li>< a href="https://www.linkedin.com/feed/">Linkedin</a></li></ul>
                            </div>

                            </div>

                            <p class="copyright">deepnostiX © 2021</p>
                        </div>
                    </footer>
                </div>

            </div>

        )
    }
}

export default withRouter(home)
