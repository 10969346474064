import React, { Component } from 'react'
import { withRouter } from 'react-router-dom';
import axios from 'axios';

export class DiseaseListButton extends Component {
    constructor(props) {
        super(props);
        this.setState({
            microbeName: this.props.microbeName,
            indexValue: this.props.indexValue,
        });
    }

    clickHandler() {
        console.log('microbe ', this.props.microbeName, ' at index ', this.props.indexValue, ' got clicked');
        var clickedMicrobeName = this.props.microbeName.toString()
        while (clickedMicrobeName[0] === ' ') {
            clickedMicrobeName = clickedMicrobeName.substring(1);
        }
        const sendMicrobeData = {
            microbeName: clickedMicrobeName
        };

        axios.post('https://clickabledummy-84cc3.uc.r.appspot.com/searchMicrobe', sendMicrobeData)
            .then(res => {

                if (res.data.errors === 'none') {
                    this.props.history.push({
                        pathname: '/microbe',
                        search: '?' + clickedMicrobeName,
                        state: { microbeData: res.data }

                    })
                }
                else {
                    alert(res.data.errors);
                }
            })
            .catch(err => {
                alert('could not get response from backend');
                console.log(err);
            });
    }

    render() {
        return (
                <td style = {{paddingLeft: "2px"}}><button style = {{ fontSize: '14px',background: "#E2E9EA",borderColor:'#1E5661', color:'#1E5661',height:'50px' ,width:'115px',borderRadius:'20%',fontFamily:'Avenir Next LT Pro'}} onClick={this.clickHandler.bind(this)}>{this.props.microbeName}</button></td>
            

        )
    } 
}

export default withRouter(DiseaseListButton)



// <div>
// <tr><td> {this.state.indexValue}<td style={{paddingLeft: "5px"}}><button></button></td></tr>
// </div>

// // <div>
// <tr><td>{this.state.indexValue}</td> <td style = {{paddingLeft: "5px"}}><button onClick={this.clickHandler.bind(this)}>{this.state.microbeName}</button></td></tr>
// </div>
