//(e) => {e.preventDefault();conosle.log("got a click ",e.target.value)}

import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import DiseaseListButton from './DiseaseListButton'

import '../App.css'

export class diseases extends Component {

    constructor(props) {
        super(props);
        this.state = {
            diseaseName: this.props.location.state.diseaseData.diseaseName,
            microbesList: this.props.location.state.diseaseData.microbesList
        };
    }

    componentDidMount() {
        this.setState({
            diseaseName: this.props.location.state.diseaseData.diseaseName,
            microbesList: this.props.location.state.diseaseData.microbesList

        });
        console.log('disease gets called...');
        console.log('got disease name ', this.state.diseaseName);
        console.log('associated microbes are: ', this.state.microbesList);
    }

    render() {

        const diseaseListButtons = this.state.microbesList.map((microbe, index) => <DiseaseListButton key={microbe} microbeName={microbe} indexValue={index + 1} />)
        return (
            <div>
                <div className="myDiseasesTable">
                 <h2 style={{fontFamily:'Avenir Next LT Pro', textAlign: 'marginLeft' }}><u><b>{this.state.diseaseName}:</b></u></h2>
                    <p>Following microbes are associated with this disease: <br></br></p>
                    <table className="diseasesTable">
                        <tbody>
                            <tr>
                                
                                <td><h3><i><u>Microbe Names:</u></i></h3></td>
                            </tr>
                            
                                {diseaseListButtons}
                                <br></br>
                            
                        </tbody>
                    </table>
                    <p><br></br>Click any microbe name to see more information.<br></br></p>
                </div>
            </div>
        )
    }
}

export default withRouter(diseases)
