import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

import PronounciationSpeaker from './pronounciationSpeaker'
import '../App.css';


export class microbe extends Component {
    
    constructor(props) {
        super(props);
        this.state = {
            microbeName: null,
            organs: null,
            phylum: null,
            diseases: null,
            description: null
        };
        
    }

    componentDidMount() {
        this.setState({
            microbeName: this.props.location.state.microbeData.microbe_name,
            organs: this.props.location.state.microbeData.organs,
            phylum: this.props.location.state.microbeData.phylum,
            diseases: this.props.location.state.microbeData.diseases,
            description: this.props.location.state.microbeData.description
            
        });
    }
    render() {
        
        return (
            
            <div className="myTable">
                <link rel="stylesheet" href="//netdna.bootstrapcdn.com/bootstrap/3.0.3/css/bootstrap.min.css" />
                <h3 id="microbeNameHeading">{this.state.microbeName}</h3>
                    <PronounciationSpeaker microbe_name = {this.state.microbeName}/>
                <table className="table">
                    <thead>
                        <tr>
                            <th style={{width: '2%'}} scope="col">#</th>
                            <th style={{width: '15%'}} scope="col">Name</th>
                            <th style={{width: '17.5%'}} scope="col">Organs</th>
                            <th style={{width: '17.5%'}} scope="col">Diseases</th>
                            <th style={{width: '8%'}} scope="col">Phylum</th>
                            <th style={{width: '40%'}} scope="col">Description</th>

                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <th scope="row">1</th>
                            <td id="microbiomeTableRow_name">{this.state.microbeName}</td>
                            <td id="microbiomeTableRow_organs">{this.state.organs}</td>
                            <td id="microbiomeTableRow_diseases">{this.state.diseases}</td>
                            <td id="microbiomeTableRow_phylum">{this.state.phylum}</td>
                            <td id="microbiomeTableRow_description">{this.state.description}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            
        )
    }
}

export default withRouter(microbe)

//<button id="pronounciationButton" style={{fontSize: '24px' , color: '#d4482f'}} onClick= {this.pronounciationClickHandler.bind(this)}>pronounciation<i
//className="fa fa-play"></i></button>